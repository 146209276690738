import withBenefitsApplication, {
  WithBenefitsApplicationProps,
} from "../../hoc/withBenefitsApplication";

import ApplicationPagePrepopulated from "src/features/universal-profiles/application-page-prepopulated";
import ConditionalContent from "../../components/ConditionalContent";
import InputChoiceGroup from "../../components/core/InputChoiceGroup";
import InputText from "../../components/core/InputText";
import QuestionPage from "../../components/QuestionPage";
import { Race as RaceOptions } from "../../models/BenefitsApplication";
import React from "react";
import { get } from "lodash";
import { isFeatureEnabled } from "src/services/featureFlags";
import useFormState from "../../hooks/useFormState";
import useFunctionalInputProps from "../../hooks/useFunctionalInputProps";
import { useTranslation } from "../../locales/i18n";

export const fields = ["claim.race", "claim.race_custom"];

export const Race = (props: WithBenefitsApplicationProps) => {
  const { appLogic, claim } = props;
  const { t } = useTranslation();

  const usedProfileData =
    isFeatureEnabled("enableUniversalProfilePilot") &&
    !!claim.fields_to_use_from_user_profile?.includes("raceEthnicity");

  const { formState, getField, updateFields, clearField } = useFormState({
    race: claim.race || RaceOptions.preferNotToAnswer,
    race_custom: claim.race_custom,
  });

  const handleSave = () =>
    appLogic.benefitsApplications.update(claim.application_id, formState);

  const getFunctionalInputProps = useFunctionalInputProps({
    errors: appLogic.errors,
    formState,
    updateFields,
  });

  const race = get(formState, "race");

  return (
    <QuestionPage
      title={t("pages.claimsRace.title")}
      dataCy="race-form"
      onSave={handleSave}
      alertBanner={usedProfileData && <ApplicationPagePrepopulated />}
    >
      <InputChoiceGroup
        {...getFunctionalInputProps("race")}
        choices={[
          {
            checked: race === RaceOptions.preferNotToAnswer,
            label: t("pages.claimsRace.choicePreferNotToAnswer"),
            value: RaceOptions.preferNotToAnswer,
          },
          {
            checked: race === RaceOptions.americanIndianAlaskaNative,
            label: t("pages.claimsRace.choiceAmericanIndianAlaskaNative"),
            value: RaceOptions.americanIndianAlaskaNative,
          },
          {
            checked: race === RaceOptions.asianAsianAmerican,
            label: t("pages.claimsRace.choiceAsianAsianAmerican"),
            value: RaceOptions.asianAsianAmerican,
          },
          {
            checked: race === RaceOptions.blackAfricanAmerican,
            label: t("pages.claimsRace.choiceBlackAfricanAmerican"),
            value: RaceOptions.blackAfricanAmerican,
          },
          {
            checked: race === RaceOptions.nativeHawaiianOtherPacificIslander,
            label: t(
              "pages.claimsRace.choiceNativeHawaiianOtherPacificIslander"
            ),
            value: RaceOptions.nativeHawaiianOtherPacificIslander,
          },
          {
            checked: race === RaceOptions.white,
            label: t("pages.claimsRace.choiceWhite"),
            value: RaceOptions.white,
          },
          {
            checked: race === RaceOptions.multiracial,
            label: t("pages.claimsRace.choiceMultiracial"),
            value: RaceOptions.multiracial,
          },
          {
            checked: race === RaceOptions.anotherRaceNotListedAbove,
            label: t("pages.claimsRace.choiceAnotherRaceNotListedAbove"),
            value: RaceOptions.anotherRaceNotListedAbove,
          },
        ]}
        type="radio"
        label={t("pages.claimsRace.sectionLabel")}
        hint={t("pages.claimsRace.sectionLabelHint")}
      />
      <ConditionalContent
        fieldNamesClearedWhenHidden={["race_custom"]}
        getField={getField}
        clearField={clearField}
        updateFields={updateFields}
        visible={race === RaceOptions.anotherRaceNotListedAbove}
      >
        <InputText
          {...getFunctionalInputProps("race_custom")}
          label={t("pages.claimsRace.customLabel")}
          smallLabel
          hint={t("pages.claimsRace.customHint")}
        />
      </ConditionalContent>
    </QuestionPage>
  );
};

export default withBenefitsApplication(Race);
