// Address Search
export class Addresses {
  address: string;
  global_address_key: string;

  constructor(attrs: Partial<Addresses>) {
    Object.assign(this, attrs);
  }
}

export class AddressResponse {
  addresses: Addresses[];
  ready_to_select_addresses = false;

  constructor(attrs: Partial<AddressResponse>) {
    Object.assign(this, attrs);
  }
}

// Address Format
export class AddressFormat {
  address_line_1: string;
  address_line_2: string;
  address_line_3: string;
  country: string;
  locality: string;
  postal_code: string;
  region: string;
  global_address_key: string;

  constructor(attrs: Partial<AddressFormat>) {
    Object.assign(this, attrs);
  }
}

export class AddressFormatResponse {
  address: AddressFormat;
  confidence: string;

  constructor(attrs: Partial<AddressFormatResponse>) {
    Object.assign(this, attrs);
  }
}

export default AddressResponse;
